.video-body-layout {
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100vh;
  background-color: rgba(
    255,
    255,
    255,
    0.85
  ) !important; /* Black background with opacity */
  z-index: 900 !important; /* Specify a stack order in case you're using a different order for other elements */
}

.video-body-flex-container {
  display: flex;
  flex-wrap: wrap-reverse;
  align-items: center;
  height: 100%;
  width: 100%;
}

.video-body-timer-value {
  color: black;
  font-family: Rubik;
  font-size: 90px;
}

.video-body-timer-title {
  color: black;
  font-size: 40px;
  font-family: Rubik;
}

.video-body-timer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.video-body-description {
  font-size: 25px;
  font-family: Rubik;
  margin-left: 30px;
}

.video-body-bottom-message {
  position: absolute;
  height: 100vh;
  padding: 30px;
}

.video-body-flex-bottom-message {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: black;
  height: 100%;
  font-family: Rubik;
}

.video-body-bottom-message-title {
  font-size: 30px;
}

.video-body-bottom-message-subtitle {
  font-size: 40px;
}

.video-body-flex-item {
  flex-basis: 33%;
  flex-grow: 1;
}

