.error-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.error-image {
    width: 169px !important;
    height: 43px !important;
}

.error-message {
    color: white;
    font-size: 20px;
    justify-content: center;
    font-family: Rubik;
    margin-top: 60px;
    text-align: center;
}