@import url("https://fonts.googleapis.com/css2?family=Rubik&display=swap");

body {
    background-color: black !important;
    height: 100%;
    font-family: Rubik;
}


.timer-route {
    position: absolute;
    left: 6.55%;
    right: -0.23%;
    top: 0%;
    bottom: 6.32%;
    display: inline-block;
    shape-outside: circle();
    clip-path: circle();
    border: 2.68189px solid #5CA459;
    transform: rotate(4.01deg);
}

.timer-clear {
    clear: both;
}

.timer-container {
    position: absolute;
    left: 81.56%;
    right: 4.71%;
    top: 5.56%;
    bottom: 70.05%;
}