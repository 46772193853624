.banner-container {
    position: fixed;
    width: 100%;
    z-index: 999;
    bottom: 10px;
    left: 0px;
}

.banner-close-container {
    display: flex;
    flex: 1;
    font-size: 14px;
    padding-right: 15px;
    padding-top: 15px;
    color: #4FC2F7;
    justify-content: flex-end;
}

.banner-close-text {
    font-size: 25px;
    cursor: pointer;
}

.banner-link-container {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    height: 100%;
    padding-top: 20px;
}

.banner-flex {
    display: flex;
    flex-wrap: wrap;
    height: 180px;
    font-family: Rubik;
    font-size: 28px;
}

.banner-image-item {
    background-color: lightgray;
    height: 100%;
    width: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
  
.banner-link-item {
    background-color: white;
    height: 100%;
    width: 25%;
    display: flex;
}

.banner-link-text {
    color: black;
}

.banner-button-container {
}