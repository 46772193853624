@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

.video-header-container {
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-between;
  color: white;
  font-family: Rubik;
  font-style: normal;
}

.video-header-logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-header-flex-item {
    flex-basis: 33%;
    flex-grow: 1;
}

.video-header-data-mobile {
  display: flex;
  flex-direction: row;
}

.video-header-data {
  display: flex;
  flex-direction: column;
}

.video-header-round {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-right: 50px;
}

.video-header-round-title {
  font-size: 28px !important;
}

.video-header-round-value {
  font-size: 18px !important;
  font-style: normal;
  color: #4FC2F7;
}

.video-header-page {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.video-header-page-title {
  font-size: 28px !important;
}

.video-header-page-value {
  font-size: 18px !important;
  font-style: normal;
  color: #4FC2F7;
}

.video-header-logo {
  display: flex;
  align-items: center;
}

.video-header-logo-mobile {
  display: none;
}

.video-header-image {
  width: 302px !important;
  height: 76px !important;
}

.video-header-timer {
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media (max-width: 500px) {
  .video-header-data-mobile {
    display: flex;
    flex-direction: column;
  }

  .video-header-logo {
    display: flex;
    align-items: center;
    display: none;
  }

  .video-header-logo-mobile {
    display: block;
  }

  .video-header-image {
    width: 169px !important;
    height: 43px !important;
  }
}
