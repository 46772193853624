@import url(https://fonts.googleapis.com/css2?family=Rubik&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik&display=swap);
.home-overlay{position:fixed;top:0;left:0;width:100% !important;height:100vh;background-color:rgba(0,0,0,0.65) !important;z-index:900 !important}.loading{display:flex;justify-content:center;align-items:center;height:100vh}

.error-container{display:flex;flex-direction:column;min-height:100vh;justify-content:center;align-items:center;padding:20px}.error-image{width:169px !important;height:43px !important}.error-message{color:white;font-size:20px;justify-content:center;font-family:Rubik;margin-top:60px;text-align:center}

.vc_container{position:relative}.vc_overlay{width:100%;position:absolute;bottom:0px}.vc_params_container{width:100%;position:absolute}.vc_params{display:flex;flex-direction:column;align-items:flex-end;font-style:normal;text-align:center;color:white;padding-right:30px;padding-top:30px}.vc_params_amount{font-size:48px;line-height:40px;height:40px;font-family:Rubik}.vc_params_unit{font-size:18px;text-transform:uppercase;font-family:Rubik}.vc_gradient{background:linear-gradient(180deg, rgba(0,0,0,0) 24.67%, rgba(0,0,0,0.65) 100%);height:130px}.vc_title{height:100%;display:flex;justify-content:left;align-items:flex-end;padding-bottom:30px;padding-left:30px;color:white;font-style:normal;font-weight:500;font-size:25px;line-height:30px;text-transform:uppercase;font-family:Rubik}

.video-body-layout{position:fixed;top:0;left:0;width:100% !important;height:100vh;background-color:rgba(255,255,255,0.85) !important;z-index:900 !important}.video-body-flex-container{display:flex;flex-wrap:wrap-reverse;align-items:center;height:100%;width:100%}.video-body-timer-value{color:black;font-family:Rubik;font-size:90px}.video-body-timer-title{color:black;font-size:40px;font-family:Rubik}.video-body-timer-container{display:flex;flex-direction:column;justify-content:center;align-items:center}.video-body-description{font-size:25px;font-family:Rubik;margin-left:30px}.video-body-bottom-message{position:absolute;height:100vh;padding:30px}.video-body-flex-bottom-message{display:flex;flex-direction:column;justify-content:flex-end;color:black;height:100%;font-family:Rubik}.video-body-bottom-message-title{font-size:30px}.video-body-bottom-message-subtitle{font-size:40px}.video-body-flex-item{flex-basis:33%;flex-grow:1}

.timer-container-layout{display:flex;flex-direction:column;align-items:center}.timer-value{font-family:Rubik;font-style:normal;font-weight:300;font-size:50px;color:white}.timer-title{font-family:Rubik;font-style:normal;font-weight:500;font-size:12px;color:#ffffff}

.video-header-container{display:flex;flex:1 1;width:100%;justify-content:space-between;color:white;font-family:Rubik;font-style:normal}.video-header-logo-container{display:flex;justify-content:center;align-items:center}.video-header-flex-item{flex-basis:33%;flex-grow:1}.video-header-data-mobile{display:flex;flex-direction:row}.video-header-data{display:flex;flex-direction:column}.video-header-round{display:flex;flex-direction:column;padding:10px;margin-right:50px}.video-header-round-title{font-size:28px !important}.video-header-round-value{font-size:18px !important;font-style:normal;color:#4FC2F7}.video-header-page{display:flex;flex-direction:column;padding:10px}.video-header-page-title{font-size:28px !important}.video-header-page-value{font-size:18px !important;font-style:normal;color:#4FC2F7}.video-header-logo{display:flex;align-items:center}.video-header-logo-mobile{display:none}.video-header-image{width:302px !important;height:76px !important}.video-header-timer{padding:10px;display:flex;justify-content:flex-end;align-items:center}@media (max-width: 500px){.video-header-data-mobile{display:flex;flex-direction:column}.video-header-logo{display:flex;align-items:center;display:none}.video-header-logo-mobile{display:block}.video-header-image{width:169px !important;height:43px !important}}

.home-overlay{position:fixed;top:0;left:0;width:100% !important;height:100vh;background-color:rgba(0,0,0,0.65) !important;z-index:900 !important}.d-none{display:none}.loading{display:flex;justify-content:center;align-items:center;height:100vh}

.button{display:flex;justify-content:center;align-items:center;font-size:14px;padding:10px;cursor:pointer}.blue{background-color:#4FC2F7}.gray{background-color:#f2f2f2}.button-text{white-space:nowrap}

.banner-container{position:fixed;width:100%;z-index:999;bottom:10px;left:0px}.banner-close-container{display:flex;flex:1 1;font-size:14px;padding-right:15px;padding-top:15px;color:#4FC2F7;justify-content:flex-end}.banner-close-text{font-size:25px;cursor:pointer}.banner-link-container{display:flex;flex-direction:column;padding-left:20px;height:100%;padding-top:20px}.banner-flex{display:flex;flex-wrap:wrap;height:180px;font-family:Rubik;font-size:28px}.banner-image-item{background-color:lightgray;height:100%;width:75%;display:flex;justify-content:center;align-items:center;cursor:pointer}.banner-link-item{background-color:white;height:100%;width:25%;display:flex}.banner-link-text{color:black}

.player-wrapper{position:relative;padding-top:56.25%}.react-player{position:absolute;top:0;left:0}.App{background-color:black;height:100%;width:100%}

body{background-color:black !important;height:100%;font-family:Rubik}.timer-route{position:absolute;left:6.55%;right:-0.23%;top:0%;bottom:6.32%;display:inline-block;shape-outside:circle();-webkit-clip-path:circle();clip-path:circle();border:2.68189px solid #5CA459;transform:rotate(4.01deg)}.timer-clear{clear:both}.timer-container{position:absolute;left:81.56%;right:4.71%;top:5.56%;bottom:70.05%}

