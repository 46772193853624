.home-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100vh;
  background-color: rgba(
    0,
    0,
    0,
    0.65
  ) !important; /* Black background with opacity */
  z-index: 900 !important; /* Specify a stack order in case you're using a different order for other elements */
}

.d-none {
 display: none;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}