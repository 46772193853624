.vc_container {
    position: relative;
}

.vc_overlay {
    width: 100%;
    position: absolute;
    bottom: 0px;
}

.vc_params_container {
    width: 100%;
    position: absolute;   
}

.vc_params {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-style: normal;
    text-align: center;
    color: white;
    padding-right: 30px;
    padding-top: 30px;
}

.vc_params_amount {
    font-size: 48px;
    line-height: 40px;
    height: 40px;
    font-family: Rubik;
}

.vc_params_unit {
    font-size: 18px;
    text-transform: uppercase;
    font-family: Rubik;
}

.vc_gradient {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 24.67%, rgba(0, 0, 0, 0.65) 100%);
    height: 130px;
}

.vc_title {
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: flex-end;
    padding-bottom: 30px;
    padding-left: 30px;
    color: white;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    text-transform: uppercase;
    font-family: Rubik;
}