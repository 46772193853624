.button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    padding: 10px;
    cursor: pointer;
}

.blue {
    background-color: #4FC2F7;
}

.gray {
    background-color: #f2f2f2;
}

.button-text {
    white-space: nowrap;
}